import React, { Fragment, PureComponent } from 'react';
import './startScreen.css';
import Game from "../game/game";

export default class StartScreen extends PureComponent {
    state = {

    }

    render() {
        return(
        <div style={{
            cursor: "pointer",
            backgroundImage: 'url("/titlescreen.jpg")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: "cover"
        }} className={'background'}>
            <div onClick={() => this.props.setMode('traktor')} className={'background-half'}>
            </div>
            <div onClick={() => this.props.setMode('barbie')} className={'background-half'}>
            </div>
        </div>)
    }
}

