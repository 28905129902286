import React, { PureComponent } from "react";

class Box extends PureComponent {
    render() {
        const size = 60;
        const x = this.props.x - size / 2;
        const y = this.props.y - size / 2;

        return (
            <div id={this.props.renderer.props.name} style={{ position: "fixed", width: size, height: size, left: x, top: y }}>
                <img id={this.props.renderer.props.name} src={`/${window.game.mode}_${this.props.type}.png`} style={{width: size}} />
            </div>
        );
    }
}

class Player extends PureComponent {
    render() {
        const size = 170 + ((window.game.level || 1)*10);
        const x = this.props.x - size / 2;
        const y = this.props.y - size / 2;
        return (
            <div id="player" style={{ position: "fixed", width: size, height: size, left: x, top: y }}>
                <img src={"/"+window.game.mode+window.game.level+".png"} style={{width: size}} />
            </div>
        );
    }
}

class Pig extends PureComponent {
    render() {
        const size = 60;
        const x = this.props.x - size / 2;
        const y = this.props.y - size / 2;
        return (
            <div id={this.props.renderer.props.name} style={{ position: "fixed", width: size, height: size, left: x, top: y }}>
                <img id={this.props.renderer.props.name} src={`/${window.game.mode}_schwein.png`} style={{width: size, transform: 'scaleX(-1)'}} />
            </div>
        );
    }
}
export { Box, Player, Pig };