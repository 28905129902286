import React, { PureComponent } from "react";
import { GameEngine } from "react-game-engine";
import {Box, Pig, Player} from "./renderers";
import {BoxHandler, DragPlayer, EnterMouse, getRandomArbitrary, getRandomFromArray, PigHandler} from "./systems"
import StartScreen from "./startScreen/startScreen";
import {Howl} from "howler";

export const topTypes = ['tomate', 'weizen', 'mais'];

export default class SimpleGame extends PureComponent {
    state = {
        mode: "",
        game: {},
    }
    screenWidth =(window.document.documentElement.clientWidth);
    screenHeight =(window.document.documentElement.clientHeight);

    initBox(num) {
        return {
            x: getRandomArbitrary(50, this.screenWidth-120),
            y: -150,
            type: getRandomFromArray(topTypes),
            renderer: <Box name={'box'+num}/>
        }
    }
    initPig(num) {
        const y = getRandomArbitrary(50, this.screenHeight-120);
        return {
            x: -150,
            y: y,
            baseY: y,
            renderer: <Pig name={'pig'+num}/>
        }
    }

    componentDidMount() {
        const fancy = new Howl({
            src: ['/audios/background.wav'],
            volume: 0.04,
            loop: true,
        });
        fancy.play()
        setInterval(() => this.updateGameState(), 100);
    }

    updateGameState() {
        if (window.game) {
            let boxAmount = 2;
            if (window.game.points > 40) {
                boxAmount = 3;
            }
            if (window.game.points > 100) {
                boxAmount = 4;
            }
            for (var x = 0; x < boxAmount; x++) {
                if (!window.game.entities['box'+x]) {
                    window.game.entities['box'+x] = this.initBox(x);
                }
            }

            let pigAmount = 1;
            if (window.game.points > 30) {
                pigAmount = 2;
            }
            for (var x = 0; x < pigAmount; x++) {
                if (!window.game.entities['pig'+x]) {
                    window.game.entities['pig'+x] = this.initPig(x);
                }
            }


            if (window.game.points > 25 && window.game.level < 2) {
                window.game.level = 2;
                this.levelUp();
            }
            if (window.game.points > 50 && window.game.level < 3) {
                window.game.level = 3;
                this.levelUp();
            }
            if (window.game.points > 100 && window.game.level < 4) {
                window.game.level = 4;
                this.levelUp();
            }
            if (window.game.points > 150 && window.game.level < 5) {
                window.game.level = 5;
                this.levelUp();
            }
            if (window.game.points > 200 && window.game.level < 6) {
                window.game.level = 6;
                this.levelUp();
            }

            this.setState({game: window.game});
        }
    }
    levelUp() {
        const fancy = new Howl({
            src: ['/audios/levelUp.wav'],
            volume: 0.5,
        });
        fancy.play();
    }

    initVars() {
        if (!window.game) {
            window.game = {
                level: 1,
                points: 0,
                mode: "",
                entities:{
                    player: { x: 500,  y: 500, renderer: <Player increaseSize={this.state.points} />},
                    box0: this.initBox(0),
                    pig0: this.initPig(0)
                },
                levelConfig: [10, 25, 50]
            }
        }
    }

    setMode(mode) {
        window.game.mode = mode;
        this.setState({mode});

        setTimeout(() => {
            const elem = document.getElementById("fScreen");
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }, 500)
    }
    render() {
        this.initVars();
        if (!this.state.mode && !window.game?.mode) {
            return (
                <StartScreen setMode={(mode) => this.setMode(mode)} />
            );
        }
        const level = window.game.level;
        return (
            <div id={"fScreen"}>
                <GameEngine
                    style={{
                        width: '100vw',
                        height: '100vh',
                        backgroundImage: 'url("/'+this.state.mode+'_background.png")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "cover"
                }}
                    systems={[DragPlayer, BoxHandler, EnterMouse, PigHandler]}
                    entities={this.state.game.entities}>
                </GameEngine>
                <div style={{position: 'absolute', top: 10, right: 10, pointerEvents: 'none'}} class="scoreText">
                    { this.state.game.points }
                </div>
                <img src={`/${this.state.mode}1.png`} style={{pointerEvents: 'none', width: '120px', position: 'absolute', top: 10, left: 10, opacity: level === 1 ? 1 : 0.2}} />
                <img src={`/${this.state.mode}2.png`} style={{pointerEvents: 'none', width: '120px', position: 'absolute', top: 10, left: 60, opacity: level === 2 ? 1 : 0.2}} />
                <img src={`/${this.state.mode}3.png`} style={{pointerEvents: 'none', width: '120px', position: 'absolute', top: 10, left: 110, opacity: level === 3 ? 1 : 0.2}} />
                <img src={`/${this.state.mode}4.png`} style={{pointerEvents: 'none', width: '120px', position: 'absolute', top: 10, left: 160, opacity: level === 4 ? 1 : 0.2}} />
                <img src={`/${this.state.mode}5.png`} style={{pointerEvents: 'none', width: '120px', position: 'absolute', top: 10, left: 210, opacity: level === 5 ? 1 : 0.2}} />
                <img src={`/${this.state.mode}6.png`} style={{pointerEvents: 'none', width: '120px', position: 'absolute', top: 10, left: 260, opacity: level === 6 ? 1 : 0.2}} />
            </div>
        );
    }
}