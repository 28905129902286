import {Howl, Howler} from 'howler';
import {topTypes} from "./App";

export function isClose(player, el, range) {
    return Math.abs(player.x - el.x) < range && Math.abs(player.y - el.y) < range;
}
export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}
export function getRandomFromArray(items) {
    return items[Math.floor(Math.random()*items.length)];
}
const screenWidth =(window.document.documentElement.clientWidth);
const screenHeight =(window.document.documentElement.clientHeight);


const DragPlayer = (entities, all) => {
    const { payload } = all.input.find(x => ["onMouseMove", "onTouchMove"].includes(x.name)) || {};

    if (payload) {
        const box1 = entities["player"];
        if (payload.type === "touchmove") {
            const touch = payload.touches[0];
            if (touch) {
                box1.x = touch.clientX;
                box1.y = touch.clientY;
            }
        } else {
            box1.x = payload.pageX;
            box1.y = payload.pageY;
        }
    }

    return entities;
};

const BoxHandler = (entities, all) => {
    const boxes = Object.keys(entities).filter(k => k.startsWith('box'));

    const player = entities["player"];

    for(const box of boxes) {
        const boxEl = entities[box];
        if (isClose(player, boxEl, 60 + (5 * (window.game.level || 1)))) {
            entities = finishBox(entities, box);
            continue;
        }
        boxEl.y = boxEl.y+0.6 + (0.0045 * (window.game.points || 1));
        if (boxEl.y > (screenHeight)-50) {
            boxEl.y = -150;
            boxEl.x = getRandomArbitrary(50, screenWidth-120);
            boxEl.type = getRandomFromArray(topTypes);
            window.game.points --;
            window.game = {...window.game};
        }
    }

    return entities;
};

const PigHandler = (entities, all) => {
    const boxes = Object.keys(entities).filter(k => k.startsWith('pig'));

    const player = entities["player"];

    for(const box of boxes) {
        const boxEl = entities[box];
        if (isClose(player, boxEl,60 + (5 * (window.game.level || 1)))) {
            entities = finishPig(entities, box);
            continue;
        }
        const changeAmount = 0.6 + (0.0045 * (window.game.points || 1));

        boxEl.x = boxEl.x + changeAmount;
        const moveMargin = 100;
        const m = boxEl.x % moveMargin;
        boxEl.y = boxEl.baseY + (m > (moveMargin/2) ? (moveMargin - m) : m);

        if (boxEl.x > (screenWidth)-50) {
            boxEl.x = -150;
            boxEl.y = getRandomArbitrary(50, screenHeight-120);
            window.game.points --;
            window.game = {...window.game};
        }

    }
    return entities;
};

const EnterMouse = (entities, all) => {
/*
    const filtered = all.input.filter(x => x.name === "onMouseOver");

    if (filtered.length) {
        for (const enterEl of filtered) {
            const elName = (enterEl.payload?.target?.id||"");
            if (elName.startsWith('box')) {
                entities = finishBox(entities, elName);
            }
            if (elName.startsWith('pig')) {
                entities = finishPig(entities, elName);
            }
        }
    }
*/
    return entities;
};

const finishBox = (entities, boxName) => {
    const sound = new Howl({
        src: ['/audios/'+window.game.mode+'_'+entities[boxName].type+'.mp3'],
        volume: 0.5,
    });
    sound.play()

    entities[boxName].y = -150;
    entities[boxName].x = getRandomArbitrary(50, screenWidth-120);
    entities[boxName].type = getRandomFromArray(topTypes);
    window.game.points ++;
    window.game = {...window.game};
    return entities;
}

const finishPig = (entities, boxName) => {
    entities[boxName].x = -150;
    const y = getRandomArbitrary(50, screenHeight-120);
    entities[boxName].y = y;
    entities[boxName].baseY = y;
    window.game.points ++;
    window.game = {...window.game};
    const sound = new Howl({
        src: ['/audios/'+window.game.mode+'_pig.mp3'],
        volume: 0.5,
    });
    sound.play()
    return entities;
}

export { DragPlayer, BoxHandler, EnterMouse, PigHandler };